'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _world_mapLines = require('../../img/world_map-lines.svg');

var _world_mapLines2 = _interopRequireDefault(_world_mapLines);

var _uikit = require('uikit');

var _uikit2 = _interopRequireDefault(_uikit);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


exports.default = {
    name: 'map-svg',
    components: {
        Map: _world_mapLines2.default
    },
    props: {
        map_data: {
            type: String,
            required: true
        },
        assets_url: {
            type: String,
            required: true
        }
    },
    data: function data() {
        return {
            map: '',
            selected: ''
        };
    },
    created: function created() {
        this.map = JSON.parse(this.map_data);
    },
    mounted: function mounted() {
        this.setMapCountries();
    },

    computed: {},
    methods: {
        mapItemFocused: function mapItemFocused(marker) {
            this.selected = marker.country.value;
        },
        mapItemUnfocused: function mapItemUnfocused(marker) {
            var oldMap = document.getElementById(marker.country.value);
            if (oldMap) {
                oldMap.classList.remove('focused');
            }
        },
        setMapCountries: function setMapCountries() {

            if (this.map.markers) {
                this.map.markers.forEach(function (marker) {
                    countries[marker.country.value] = marker.country.label;
                    var marker_country = document.getElementById(marker.country.value);
                    marker_country.classList.add('selected');
                });
            }
            if (this.map.highlights) {
                this.map.highlights.forEach(function (highlight) {
                    var highlight_country = document.getElementById(highlight.country.value);
                    if (highlight_country) {
                        highlight_country.classList.add('highlighted');
                        _uikit2.default.tooltip(highlight_country, {
                            'title': highlight_country.getAttribute("aria-label"),
                            'pos': 'top',
                            'offset': -25
                        });
                    }
                });
            }
        }
    },
    watch: {
        selected: function selected(newSelected, oldSelected) {
            var oldMap = document.getElementById(oldSelected);
            var newMap = document.getElementById(newSelected);

            if (oldMap) {
                oldMap.classList.remove('focused');
            }
            if (newMap) {
                newMap.classList.add('focused');
            }
        }
    }
};