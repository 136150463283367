'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _uikit = require('uikit');

var _uikit2 = _interopRequireDefault(_uikit);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'section-popup-modal',
    props: {
        page_id: {
            required: true
        },
        section_data: {
            type: String,
            required: true
        }
    },
    data: function data() {
        return {
            section: '',
            content: '',
            image: '',
            cta: '',
            options: {
                delay: "1500",
                trigger: "onload",
                valign: "top"
            },

            cls_content_size: 'uk-text-medium',
            cls_content_alingment: 'uk-text-left',

            cls_footer_alignment: 'uk-text-right',

            cls_modal_width: '',
            cls_modal_valign: '',

            cls_image_dir: 'uk-flex-first@s',
            cls_image_width: 'uk-width-1-1@s'
        };
    },
    created: function created() {
        this.initModal();
        this.setSection();
    },
    mounted: function mounted() {
        this.showModal(this.options.trigger);
    },

    methods: {
        setSection: function setSection() {
            this.section = JSON.parse(this.section_data);
            this.content = this.section.content;
            this.image = this.section.image;
            this.cta = this.section.cta;
            this.options = this.section.options;

            this.cls_content_size = 'uk-text-' + this.content['text_size'];
            this.cls_content_alingment = 'uk-text-' + this.content.alignment;
            this.cls_footer_alignment = this.content.alignment == 'center' ? this.cls_content_alingment : this.cls_footer_alignment;

            if (this.options.valign == 'center') {
                this.cls_modal_valign = 'uk-margin-auto-vertical';
            }

            if (this.image.enable === true && this.image.width > 1) {
                this.cls_modal_width = 'uk-modal-container';
                this.cls_image_width = 'uk-width-1-' + this.image['width'] + '@s';
                this.cls_image_dir = 'uk-flex-' + this.image.direction + '@s';
            }
        },
        initModal: function initModal() {
            this.$store.commit('initPageModal', {
                page_id: this.page_id
            });
        },
        showModal: function showModal() {
            var trigger = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'onload';

            if (this.modalState === true) {
                var element = document.getElementById('section-popup-modal');

                var self = this;
                if (trigger == 'onload') {
                    setTimeout(function () {
                        _uikit2.default.modal(element).show();
                    }, self.options.delay);
                } else if (trigger == 'scroll') {
                    _uikit2.default.scrollspy('#section-popup-modal-wrapper', {});
                    _uikit2.default.util.on('#section-popup-modal-wrapper', 'inview', function () {
                        self.showModal();
                    });
                }
            }
        },
        hideModal: function hideModal() {
            var element = document.getElementById('section-popup-modal');
            setTimeout(function () {
                _uikit2.default.modal(element).hide();
            }, 350);
            // this.toggleModal() 
        },
        toggleModal: function toggleModal() {
            this.$store.commit('togglePageModal', {
                page_id: this.page_id
            });
        }
    },
    computed: {
        modalState: function modalState() {
            return this.$store.getters.getPageModalState(this.page_id);
        }
    }
}; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//