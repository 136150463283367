'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _vue = require('vue');

var _vue2 = _interopRequireDefault(_vue);

var _vuex = require('vuex');

var _vuex2 = _interopRequireDefault(_vuex);

var _vuexPersistedstate = require('vuex-persistedstate');

var _vuexPersistedstate2 = _interopRequireDefault(_vuexPersistedstate);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_vue2.default.use(_vuex2.default);

var state = {
    // siteForms: {},
    siteVars: tw_wp,
    pageModals: {}
};
var getters = {
    // getSiteForms(state){
    //     return state.siteForms;
    // },
    // getSiteFormById: (state, getters) => (id) => {
    //     return state.siteForms[id]
    // },
    getPageModalState: function getPageModalState(state, getters) {
        return function (page_id) {
            return state.pageModals[page_id];
        };
    }
};

var mutations = {
    // initSiteForms(state, value){
    //     let forms = {}

    //     for(let id in tw_wp.gravityforms){
    //         forms[id] = {
    //             'id': id,
    //             'name': tw_wp.gravityforms[id].name,
    //             'currentPage': 1,
    //         }
    //     }
    //     state.siteForms = forms
    // },
    // initSiteForm(state, form){
    //     if(form.pagination){
    //         let formPages = form.pagination.pages
    //         let pages = {}
    //         for(let i =0; i<formPages.length; i++){
    //             let pageNumber = i+1
    //             pages[pageNumber] = {
    //                 'pageNumber' : pageNumber,
    //                 'name' : formPages[i],
    //                 'isComplete' : false,
    //             }
    //         }
    //         state.siteForms[form.id].pages = pages
    //     }
    // },
    // updateSiteFormCurrentPage(state, value){
    //     state.siteForms[value.id].currentPage = value.nextPage;
    // },
    twChangeTitle: function twChangeTitle(state, value) {
        // mutate state
        state.title = value;
        document.title = (state.title ? state.title + ' - ' : '') + tw_wp.site_name;
    },
    retriveSiteFormConformationPage: function retriveSiteFormConformationPage(state, value) {
        var pageHTML = value;
        state.siteForms[form.id].confirmationPage = pageHTML;
    },
    initPageModal: function initPageModal(state, value) {
        if (state.pageModals[value.page_id] === undefined) {
            state.pageModals[value.page_id] = true;
        }
    },
    togglePageModal: function togglePageModal(state, value) {
        state.pageModals[value.page_id] = !state.pageModals[value.page_id];
    }
};

var actions = {};

exports.default = new _vuex2.default.Store({
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {},
    plugins: [(0, _vuexPersistedstate2.default)({
        key: tw_wp.site_url,
        storage: window.sessionStorage
    })],
    strict: true
});