'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.importComponents = undefined;

var _vue = require('vue');

var _vue2 = _interopRequireDefault(_vue);

var _store = require('./store');

var _store2 = _interopRequireDefault(_store);

var _MapSVG = require('./components/MapSVG.vue');

var _MapSVG2 = _interopRequireDefault(_MapSVG);

var _PostAudio = require('./components/PostAudio.vue');

var _PostAudio2 = _interopRequireDefault(_PostAudio);

var _PopUpModal = require('./components/PopUpModal.vue');

var _PopUpModal2 = _interopRequireDefault(_PopUpModal);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function importComponents() {
    // Other Components
    // Vue.component('map-svg', MapSvg)
    if (document.getElementById('section-map-svg')) {
        new _vue2.default({
            components: {
                MapSvg: _MapSVG2.default
            }
        }).$mount('#section-map-svg');
    }

    // Vue.component('post-audio', PostAudio)
    if (document.getElementById('content-audio')) {
        new _vue2.default({
            components: {
                PostAudio: _PostAudio2.default
            }
        }).$mount('#content-audio');
    }

    if (document.getElementById('content-section-popup-modal')) {
        new _vue2.default({
            store: _store2.default,
            components: {
                PopUpModal: _PopUpModal2.default
            }
        }).$mount('#content-section-popup-modal');
    }
}

exports.importComponents = importComponents;