var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "map-svg-component uk-position-relative uk-padding-remove-horizontal",
      attrs: { id: "map-svg-component" }
    },
    [
      _vm.map.markers
        ? _c(
            "div",
            { staticClass: "section-map", attrs: { id: "map-wrapper" } },
            [
              _c(
                "div",
                { staticClass: "uk-inline" },
                [
                  _vm._l(_vm.map.markers, function(marker, index) {
                    return _c("div", { key: index }, [
                      _c("button", {
                        staticClass:
                          "uk-position-absolute uk-transform-center uk-visible@s",
                        style:
                          "left: " +
                          marker.coordinates.left +
                          "%; top: " +
                          marker.coordinates.top +
                          "%",
                        attrs: {
                          id: "marker-" + index + "-" + marker.country.value,
                          title: marker.country.label,
                          "uk-marker": ""
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "article",
                        {
                          staticClass: "uk-card uk-card-small uk-card-default",
                          attrs: {
                            id:
                              "marker-drop-" +
                              index +
                              "-" +
                              marker.country.value,
                            "uk-drop":
                              "boundary: .section-map; pos:top; mode: hover; toggle: #marker-" +
                              index +
                              "-" +
                              marker.country.value
                          },
                          on: {
                            shown: function($event) {
                              return _vm.mapItemFocused(marker)
                            },
                            hidden: function($event) {
                              return _vm.mapItemUnfocused(marker)
                            }
                          }
                        },
                        [
                          marker.image
                            ? _c("div", { staticClass: "uk-card-media-top" }, [
                                _c(
                                  "figure",
                                  { staticClass: "uk-margin-remove" },
                                  [
                                    _c("img", {
                                      staticClass: "uk-width-1-1",
                                      attrs: {
                                        "data-src":
                                          marker.image.sizes["16x6-medium"],
                                        width:
                                          marker.image.sizes[
                                            "16x6-medium-width"
                                          ],
                                        height:
                                          marker.image.sizes[
                                            "16x6-medium-height"
                                          ],
                                        alt: marker.image.alt,
                                        itemprop: "image",
                                        "uk-img": ""
                                      }
                                    })
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("header", { staticClass: "uk-card-header" }, [
                            _c(
                              "h5",
                              {
                                staticClass:
                                  "uk-h5 uk-margin-remove-bottom uk-dark"
                              },
                              [_vm._v(_vm._s(marker.subtitle))]
                            ),
                            _vm._v(" "),
                            _c(
                              "h4",
                              {
                                staticClass:
                                  "uk-card-title uk-h4 uk-margin-small-bottom uk-margin-remove-top"
                              },
                              [_vm._v(_vm._s(marker.title))]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "uk-text-small" }, [
                              _vm._v(_vm._s(marker.description))
                            ])
                          ])
                        ]
                      )
                    ])
                  }),
                  _vm._v(" "),
                  _c("Map", { attrs: { id: "map-svg" } })
                ],
                2
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.map.markers
        ? _c("div", { staticClass: "uk-container uk-hidden@s" }, [
            _c(
              "div",
              {
                staticClass: "layout-marker-sliders uk-slider",
                attrs: {
                  id: "map-svg-slider",
                  "uk-slider":
                    "center: false;autoplay: false; finite: false; sets: false;"
                }
              },
              [
                _c("div", { staticClass: "uk-position-relative" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "uk-slider-container uk-padding uk-padding-remove-top uk-padding-remove-horizontal"
                    },
                    [
                      _c(
                        "ul",
                        {
                          staticClass:
                            "uk-slider-items uk-child-width-1-1 uk-child-width-1-3@s uk-grid uk-grid-small uk-grid-match",
                          attrs: { "uk-grid": "" }
                        },
                        _vm._l(_vm.map.markers, function(marker, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              staticClass: "loop-container",
                              attrs: {
                                id:
                                  "marker-card-" +
                                  index +
                                  "-" +
                                  marker.country.value
                              },
                              on: {
                                itemshown: function($event) {
                                  return _vm.mapItemFocused(marker)
                                }
                              }
                            },
                            [
                              _c(
                                "article",
                                {
                                  staticClass:
                                    "uk-card uk-card-small uk-card-default"
                                },
                                [
                                  marker.image
                                    ? _c(
                                        "div",
                                        { staticClass: "uk-card-media-top" },
                                        [
                                          _c(
                                            "figure",
                                            { staticClass: "uk-margin-remove" },
                                            [
                                              _c("img", {
                                                staticClass: "uk-width-1-1",
                                                attrs: {
                                                  "data-src":
                                                    marker.image.sizes[
                                                      "16x6-medium"
                                                    ],
                                                  width:
                                                    marker.image.sizes[
                                                      "16x6-medium-width"
                                                    ],
                                                  height:
                                                    marker.image.sizes[
                                                      "16x6-medium-height"
                                                    ],
                                                  alt: marker.image.alt,
                                                  itemprop: "image",
                                                  "uk-img": ""
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "header",
                                    { staticClass: "uk-card-header" },
                                    [
                                      _c(
                                        "h5",
                                        {
                                          staticClass:
                                            "uk-h5 uk-margin-remove-bottom uk-dark"
                                        },
                                        [_vm._v(_vm._s(marker.subtitle))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "uk-card-title uk-h4 uk-margin-small-bottom uk-margin-remove-top"
                                        },
                                        [_vm._v(_vm._s(marker.title))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "uk-text-small" },
                                        [_vm._v(_vm._s(marker.description))]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("a", {
                    staticClass:
                      "uk-position-center-left-out uk-position-small uk-visible@s",
                    attrs: {
                      href: "#",
                      title: "Previous",
                      "uk-slidenav-previous": "",
                      "uk-slider-item": "previous",
                      "aria-label": "previous"
                    }
                  }),
                  _vm._v(" "),
                  _c("a", {
                    staticClass:
                      "uk-position-center-right-out uk-position-small uk-visible@s",
                    attrs: {
                      href: "#",
                      title: "Next",
                      "uk-slidenav-next": "",
                      "uk-slider-item": "next",
                      "aria-label": "next"
                    }
                  }),
                  _vm._v(" "),
                  _c("ul", {
                    staticClass:
                      "uk-slider-nav uk-dotnav uk-flex-center  uk-margin"
                  })
                ])
              ]
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }