var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "section-popup-modal-wrapper" } }, [
    _c(
      "div",
      {
        class: [_vm.cls_modal_width, "uk-flex-top"],
        attrs: {
          id: "section-popup-modal",
          "uk-modal": "esc-close: true; bg-close: true;"
        }
      },
      [
        _c("section", { class: ["uk-modal-dialog", _vm.cls_modal_valign] }, [
          _c("button", {
            staticClass: "uk-modal-close-default uk-close-large",
            attrs: { type: "button", "uk-close": "" },
            on: { click: _vm.toggleModal }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "uk-grid-collapse  uk-flex-middle",
              attrs: { "uk-grid": "", "uk-height-match": "" }
            },
            [
              _vm.image["enable"]
                ? _c(
                    "div",
                    {
                      class: [
                        "uk-cover-container uk-flex-first uk-width-1-1",
                        _vm.cls_image_width,
                        _vm.cls_image_dir
                      ]
                    },
                    [
                      _c("img", {
                        staticClass: "uk-width-1-1",
                        attrs: {
                          "data-src":
                            _vm.image["image"]["sizes"]["medium_large"],
                          width:
                            _vm.image["image"]["sizes"]["medium_large-width"],
                          height:
                            _vm.image["image"]["sizes"]["medium_large-height"],
                          alt: _vm.image["alt"],
                          "uk-img": "",
                          "uk-cover": ""
                        }
                      }),
                      _vm._v(" "),
                      _c("canvas", {
                        attrs: {
                          width:
                            _vm.image["image"]["sizes"]["medium_large-width"],
                          height:
                            _vm.image["image"]["sizes"]["medium_large-height"]
                        }
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "uk-width-expand" }, [
                _vm.content["title"] || _vm.content["subtitle"]
                  ? _c(
                      "header",
                      { class: ["uk-modal-header", _vm.cls_content_alingment] },
                      [
                        _vm.content["title"]
                          ? _c(
                              "h3",
                              {
                                staticClass:
                                  "uk-modal-title uk-margin-small-bottom"
                              },
                              [_vm._v(_vm._s(_vm.content["title"]))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.content["subtitle"]
                          ? _c("h4", { staticClass: "uk-margin-remove-top" }, [
                              _vm._v(_vm._s(_vm.content["subtitle"]))
                            ])
                          : _vm._e()
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.content["description"]
                  ? _c("div", {
                      class: [
                        "uk-modal-body",
                        "uk-text-" + _vm.content["text_size"],
                        _vm.cls_content_alingment
                      ],
                      domProps: {
                        innerHTML: _vm._s(_vm.content["description"])
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.cta["enable"]
                  ? _c(
                      "footer",
                      { class: ["uk-modal-footer", _vm.cls_footer_alignment] },
                      [
                        _vm.cta["behaviour"] == "link"
                          ? _c(
                              "a",
                              {
                                class: [
                                  "uk-button uk-icon-link",
                                  "uk-button-" + _vm.cta["style"]
                                ],
                                attrs: {
                                  href: _vm.cta["link"]["url"],
                                  target: _vm.cta["link"]["target"],
                                  "uk-icon": "arrow-right"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.cta["title"]) +
                                    "\n                        "
                                )
                              ]
                            )
                          : _vm.cta["behaviour"] == "scroll"
                          ? _c(
                              "a",
                              {
                                class: [
                                  "uk-button uk-icon-link",
                                  "uk-button-" + _vm.cta["style"]
                                ],
                                attrs: {
                                  href: ["#" + _vm.cta["anchor"]],
                                  "uk-scroll": "",
                                  "uk-icon": "arrow-down"
                                },
                                on: { click: _vm.hideModal }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.cta["title"]) +
                                    "\n                        "
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  : _vm._e()
              ])
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }