var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "post-audio", attrs: { id: "post-audio" } },
    [
      _c("aplayer", {
        attrs: {
          music: {
            title: this.title,
            artist: this.author,
            src: this.audio,
            pic: this.poster
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }