'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _vueAplayer = require('vue-aplayer');

var _vueAplayer2 = _interopRequireDefault(_vueAplayer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
    name: 'post-audio',
    // data() {
    //     return {
    //         title: 'secret base~君がくれたもの~',
    //         author: 'Silent Siren',
    //         audio: 'https://moeplayer.b0.upaiyun.com/aplayer/secretbase.mp3',
    //         poster: 'https://moeplayer.b0.upaiyun.com/aplayer/secretbase.jpg',

    // },
    props: {
        title: '',
        author: '',
        audio: '',
        poster: ''
    },
    components: {
        Aplayer: _vueAplayer2.default
    }
}; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//